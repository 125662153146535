import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useTagSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const ui = {
    stateList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
  }

  return {
    ui,
    stateOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useTagList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '標籤', key: 'tag', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchState = ref(null)
  const blankTagData = {
    id: null,
    tag: null,
    state: false,
    created_at: null,
    updated_at: null,
  }

  const setTagDelete = (...arg) => store.dispatch('admin-tag/setTagDelete', ...arg)
  const setTagCreate = (...arg) => store.dispatch('admin-tag/setTagCreate', ...arg)
  const setTagUpdate = (...arg) => store.dispatch('admin-tag/setTagUpdate', ...arg)

  const getTagListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    store.dispatch('admin-tag/getTagList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankTagData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    timeRange,
    blankTagData,
    refetchData,
    refetchTable,

    setTagDelete,
    setTagCreate,
    setTagUpdate,
    getTagListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
